// @import '../styles/variables.scss';
// @import '../styles/typography.scss';
// @import '../styles/layout.scss';

$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

.happy-birthday {
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding-top:var(--double-spacing);
  padding-bottom: var(--double-spacing);

  // width: 50%;
  // background-color: #F9FAFC;
  .logo {
    @media (max-width: ($sm - 1px)) {
      margin-left: 0px;
      background-size: 80%;
      margin-right: 0px;
    }

    height: 175px;
    width: 165px;
    top: 0px;
    z-index: 0;
    background-repeat: no-repeat;
    margin-left: 50px;
    margin-right: 50px;

    &:hover {
      text-decoration: none;
    }
  }

  .inscription {
    @media (max-width: ($lg - 1px)) {
      background-size: 80%;
    }

    @media (max-width: ($md - 1px)) {
      background-size: 100%;
    }

    @media (max-width: ($sm - 1px)) {
      background-size: 90%;
      margin-left: -12px;
      margin-top: 24px;
    }

    width: 50%;
    height: 180px;
    margin-top: 24px;
    background-size: 60%;
    top: 0px;
    z-index: 0;
    background-repeat: no-repeat;
  }

}