$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

.banner-family {
  width: 100%;
  position: relative;

  &__img {
    width: 100%;
    position: relative;
  }

  &__logo {
    position: absolute;
    width: 22vw;
    height: 22vw;
    top: 2vw;
    left: 20vw;
  }

  &__text-bg {
    @media (max-width: $md) {
      height: 251px;
    }

    @media (max-width: $sm) {
      height: 151px;
    }

    width: 100%;
    background-color: #759AAD;
    height: 451px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__title {
    width: 92%;
    height: 40%;
  }

  // h1 {
  //   font-family: Sverdlovsk;
  //   font-weight: 400;
  //   line-height: 292.23px;
  //   text-align: left;
  //   color: #FFFFFF;
  //   // border: 1.5px solid #FFFFFF
  //   font-size: 257px;
  // }
}