/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_tree .
 *= require_self
 */

@import "assets/stylesheets/fonts.scss";
@import "assets/stylesheets/bootstrap-override.scss";
@import "assets/stylesheets/fontello-embedded.scss";

$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

:root {
  @media (max-width: $md) {
    --main-spacing: 14px;
    --double-spacing: 28px;
  }

  --danger-bg:#e637571a;
  --danger:#E63757;
  --primary:#2C7BE5;
  --gray-1: #333333;
  --caption-color: #6E84A3;
  --label: #95AAC9;
  --admin-background: #F9FAFC;
  --separator-color: #E3EBF6;
  --select-hover: "red";
  --main-spacing:28px;
  --double-spacing: 56px;
}

.logo_img {
  background-image: url('./assets/images/interdom-logo.svg');
}

.header_logo_img {
  background-image: url('./assets/images/header_logo.svg');
}

.logo_interdom {
  background-image: url('./assets/images/logo-interdom-2.svg');
}

.logo-interdom-b {
  background-image: url('./assets/images/logo_interdom_b.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.family-title-img {
  background-image: url('./assets/images/family_title.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.logo_interdom_small {
  background-image: url('./assets/images/logo-interdom-2-small.svg');
}

.happy_birthday {
  background-image: url('./assets/images/happy_birthday.png');
}

.logo_minpros {
  background-image: url('./assets/images/logo-minpros.png');
}

.App {

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  * {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  }

  max-width: 100vw;
  overflow-x: hidden;
}

strong.semibold {
  font-weight: 600;
}

/*
  Typography
*/
.caption-regular {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: var(--caption-color);
  letter-spacing: -0.078px;
}

.h1-bold {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.326451px;
  color: #000000;
  margin-bottom: 36px;
}

.headline {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #000000;
}