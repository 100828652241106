$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

.text-block-family {
  background-color: white;
  position: relative;

  hyphens: none;

  padding-top: 4rem;
  padding-bottom: 4rem;

  p {
    @media (max-width: $xl) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: $lg) {
      font-size: 21px;
      line-height: 32px;
    }

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 21px;
    }

    font-family: 'Golos Text';
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    text-align: left;
    color: #09153F;
    hyphens: none;
    letter-spacing: 0;
    // letter-spacing: 0.04em;

  }
}

.left-bg-img {
  background-image: url('../../assets/images/pattern-1-l.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 80%;
  width: 40%;
}

.right-bg-img {
  background-image: url('../../assets/images/pattern-1-r.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 90%;
  width: 60%;
}