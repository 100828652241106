// @import '../styles/variables.scss';
// @import '../styles/typography.scss';
// @import '../styles/layout.scss';

$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

%text {
  /* PN Text Regular */

  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

%text-semibold {
  /* PN Text Semibold */
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

%subhead-semibold {
  /* PN Subhead Semibold */
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.154px;
}

%caption1 {
  /* /PN Caption 1 Regular */
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.078px;
}

.footer {
  @media (max-width: ($sm - 1px)) {
    text-align: center;
  }

  background-color: var(--text);
  position: relative;

  .btn-login {
    padding: 0 27px;
    @extend %subhead-semibold;
    color: var(--text);
  }

  .footer {
    &__column {
      @media (max-width: ($sm - 1px)) {
        text-align: center;
        justify-content: center;
        align-items: center;
      }

      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__menu-item {
      @extend %text-semibold;
      text-align: left;
      color: #000000;
      margin-bottom: 18px;
    }

    &__contacts {
      @extend %text;
      color: var(--label);
      margin-bottom: 25px;

      p {
        white-space: pre-line;
      }

      a {
        color: var(--label);
      }
    }

    &__social-container {
      @media (max-width: ($lg - 1px)) {
        text-align: center;
        align-items: flex-start;
      }

      @media (max-width: ($sm - 1px)) {
        text-align: center;
        align-items: center;
      }

      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }

    &__social {
      @media (max-width: ($md - 1px)) {
        text-align: center;
        justify-content: flex-start;
      }

      @media (max-width: ($sm - 1px)) {
        text-align: center;
        justify-content: center;
      }

      display: flex;
      justify-content: flex-end;
      flex-direction: row;

      a {
        margin-right: 26px;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-radius: 18px;
        display: flex;
        width: 35px;
        height: 35px;
        text-decoration: none;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          text-decoration: none;
        }

        color:var(--label);

        i {
          &.icon-youtube {
            margin-left: -1px;
            margin-top: 1px;
          }

          &.icon-insta {
            margin-top: 1px;
          }

          font-size: 15px;
        }
      }
    }

    &__top {
      background-color: #F9FAFC;
      border-bottom: 1px solid #FFFFFF;
    }

    &__top-row {
      @media (max-width: ($md - 1px)) {
        background-position-x: center;
        background-position-y: 30px;
        padding: 56px 0 56px 0;
      }

      @media (max-width: ($sm - 1px)) {
        padding: 28px 0 28px 0;
      }

      padding:92px 0 92px 0;
      background-repeat: no-repeat;
      background-position-x: 134px;
      background-position-y: -40px;
      justify-content: space-evenly;
    }

    &__copyright {
      @media (max-width: ($md - 1px)) {
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        margin-bottom: 12px;
      }

      @media (max-width: ($sm - 1px)) {
        text-align: center;
        align-items: center;
      }

      color:var(--label);
      @extend %caption1;
      flex-wrap: wrap;

      a {
        color: var(--label);

        &:hover {
          color: var(--label);
          text-decoration: none;
        }
      }
    }

    &__developers {
      @media (max-width: ($lg - 1px)) {
        margin-top: 14px;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
      }

      @media (max-width: ($md - 1px)) {
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
      }

      @media (max-width: ($sm - 1px)) {
        align-items: center;
      }

      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .logo_designer_img {
        height: 24px;
        width: 84px;
        margin-left: 18px;
      }

      .logo_solorum_img {
        height: 24px;
        width: 93px;
        margin-left: 18px;
      }
    }

    &__developer {
      @media (max-width: ($lg - 1px)) {
        margin-bottom: 12px;
      }

      @media (max-width: ($md - 1px)) {
        margin-right: 0px;
      }

      @extend %caption1;
      color:var(--label);
      display: flex;
      flex-direction: row;
      align-items: center;

      a:hover {
        text-decoration: none;
      }

      margin-right: 50px;

      &:last-child {
        margin-right: 0px;
      }
    }

    &__bottom {
      margin-top: 25px;
      margin-bottom: 60px;
    }
  }

  .btn.btn-primary {
    justify-self: flex-end;
  }
}