/* This stylesheet generated by Transfonter (https://transfonter.org) on June 21, 2017 9:51 AM */

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/MontserratRegular.eot');
	src: 
		url('../fonts/MontserratRegular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MontserratRegular.woff') format('woff'),
		url('../fonts/MontserratRegular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/MontserratBold.eot');
	src: 
		url('../fonts/MontserratBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MontserratBold.woff') format('woff'),
		url('../fonts/MontserratBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/MontserratLight.eot');
	src: 
		url('../fonts/MontserratLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MontserratLight.woff') format('woff'),
		url('../fonts/MontserratLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/MontserratSemiBold.eot');
	src:
		url('../fonts/MontserratSemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MontserratSemiBold.woff') format('woff'),
		url('../fonts/MontserratSemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Sverdlovsk';
	src: url('../fonts/Sverdlovsk.otf');
	src:
		url('../fonts/Sverdlovsk.woff') format('woff'),
		url('../fonts/Sverdlovsk.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

// @font-face {
//     font-family: 'Golos Text';
//     src: url('../fonts/GolosText-Medium.eot');
//     src: 
//         url('../fonts/GolosText-Medium.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/GolosText-Medium.woff2') format('woff2'),
//         url('../fonts/GolosText-Medium.woff') format('woff'),
//         url('../fonts/GolosText-Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Golos Text';
//     src: url('../fonts/GolosText-Black.eot');
//     src: 
//         url('../fonts/GolosText-Black.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/GolosText-Black.woff2') format('woff2'),
//         url('../fonts/GolosText-Black.woff') format('woff'),
//         url('../fonts/GolosText-Black.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Golos Text';
//     src: url('../fonts/GolosText-DemiBold.eot');
//     src: 
//         url('../fonts/GolosText-DemiBold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/GolosText-DemiBold.woff2') format('woff2'),
//         url('../fonts/GolosText-DemiBold.woff') format('woff'),
//         url('../fonts/GolosText-DemiBold.ttf') format('truetype');
//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Golos Text';
//     src: url('../fonts/GolosText-Bold.eot');
//     src: 
//         url('../fonts/GolosText-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/GolosText-Bold.woff2') format('woff2'),
//         url('../fonts/GolosText-Bold.woff') format('woff'),
//         url('../fonts/GolosText-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
// }

@font-face {
    font-family: 'Golos Text';
    src: url('../fonts/GolosText-Regular.eot');
    src: 
        url('../fonts/GolosText-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GolosText-Regular.woff2') format('woff2'),
        url('../fonts/GolosText-Regular.woff') format('woff'),
        url('../fonts/GolosText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
