$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

%h1-semibold {
    /* PN Title 1 Semibold */
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.326451px;
}

%headline {
    /* PN Headline Regular */

    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.32px;
}

%h2-semibold {
    /* PN Title 2 Semibold */
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.38px;
}

.family-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding-top: var(--main-spacing);
    // padding-bottom: var(--main-spacing);
    position: relative;
    width: 100%;

    &__items-container {
        width: 100%;
    }

    .family-list-pattern {
        background-image: url('../../assets/images/pattern-big.png');
        background-size: contain;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
        top: 0;
        pointer-events: none;
    }

    p {
        @extend %headline;
        margin: 0;
        margin-top: var(--double-spacing);
        white-space: pre-line;
    }

    .icon-info {
        font-size: 16px;
        margin-right: 10px;
        position: relative;
        top: 0px
    }

    h2 {
        @extend %h1-semibold;
        margin-top: var(--main-spacing);
        margin-right: 23px;
        margin-bottom: 5px;
    }

    .btn {
        margin-top: var(--main-spacing);
    }

    .gallery {
        &__image {
            height: 420px;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            margin-top: var(--main-spacing);
        }

        &__container {
            display: flex;
            padding-left: 65px;
            border-left: 3px solid var(--primary);
            margin-top: var(--double-spacing);
        }

        &__items-container {
            @media (max-width:$lg) {
                grid-template-columns: minmax(0, 1fr);
            }

            display: grid;
            grid-gap: var(--main-spacing);
            margin-top: var(--main-spacing);
            margin-bottom: var(--double-spacing);

            width: 100%;

        }
    }

    .family-item {
        position: relative;
        overflow: hidden;
        min-height: 252px;
        // padding-top: var(--main-spacing);
        // padding-bottom: var(--main-spacing);
        // margin-bottom: var(--double-spacing);

        padding-top: 4rem;
        padding-bottom: 4rem;

        &_with-image {
            grid-row: span 2;
            padding: 0;
        }

        &__dark-bg {
            background-color: #759AAD;
        }

        &__photos {
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: space-between;

            &__one {
                justify-content: center;
            }

            &__start {
                align-items: start;
            }
        }

        h2 {
            @extend %h2-semibold;
            margin: 0;
        }

        p {
            @media (max-width: $xl) {
                font-size: 21px;
                line-height: 32px;
            }

            @media (max-width: $lg) {
                font-size: 21px;
                line-height: 32px;
            }

            @media (max-width: $sm) {
                font-size: 14px;
                line-height: 21px;
            }

            // padding-right: 32px;
            margin:0;
            // margin-top: 14px;
            hyphens: none;
            // text-align: justify;
            // hyphens: auto;
            // -ms-hyphens: auto;
            // -moz-hyphens: auto;
            // -webkit-hyphens: auto;

            font-family: 'Golos Text';
            font-size: 29px;
            font-weight: 400;
            line-height: 42px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #031721;
        }

        .headtitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: -0.32px;

            margin-top: 14px;
        }

        .two-column-text {
            @media (max-width: $lg) {
                column-count: 1;
            }

            column-count: 2;
            column-gap: 53px;
        }

        &__photo {

            &_container {
                @media (max-width: ($md - 1)) {
                    flex-direction: column;
                }

                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                padding-left: 12px;
                padding-right: 12px;
            }

            &_main {
                @media (max-width: ($xl - 1)) {
                    // height: 157px;
                }

                @media (max-width: ($lg - 1)) {
                    // height: 157px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                    width: 100%;
                    height: auto;
                }

                width: auto;
                // max-width: 100%;
                // min-height: 100%;
                // height: 100%;
                z-index: 200;

                // object-fit: contain;
                // min-height: 70px;
                // max-height: 200px;
                // height: 222px;
                // display: flex;
                // align-items: center;

                // border-radius: 2px;
                // -webkit-border-radius: 6px;
                // -moz-border-radius: 8px;
            }
        }
    }
}