$xl:1200px;
$lg:992px;
$md:768px;
$sm:576px;


%h1 {
  /* PN Title 1 Bold */
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.38px;
}
%h1-semibold {
  /* PN Title 1 Semibold */
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.326451px;
}
%headline {
  /* PN Headline Regular */

  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
}
%headline-semibold {
  /* PN Headline Regular */

  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
}
%h2-semibold {
  /* PN Title 2 Semibold */
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
}
%text {
  /* PN Text Regular */

  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.gallery {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top:var(--main-spacing);
  padding-bottom:var(--main-spacing);
  p {
    @extend %headline;
    margin:0;
    margin-top: var(--double-spacing);
    white-space: pre-line;
  }
  .slogan {
    @extend %h1;
    color:var(--primary);
    padding-left: 65px;
    border-left:3px solid var(--primary);
    margin-top: var(--double-spacing);
  }
  .icon-info {
    font-size:16px;
    margin-right: 10px;
    position: relative;
    top:0px
  }
  h2 {
    @extend %h1-semibold;
    margin-top:var(--main-spacing);
    margin-right: 23px;
    margin-bottom: 5px;
  }
  .btn {
    margin-top: var(--main-spacing);
  }
  .gallery {
    &__image {
      height:420px;
      width:100%;
      max-width: 100%;;
      object-fit: cover;
      margin-top: var(--main-spacing);
    }
    &__container {
      display: flex;
      padding-left: 65px;
      border-left:3px solid var(--primary);
      margin-top: var(--double-spacing);
    }
    &__items-container {
      @media (max-width:$lg) {
        grid-template-columns: minmax(0, 1fr);
      }
      display: grid;
      // grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-gap: var(--main-spacing);
      margin-top: var(--main-spacing);
      margin-bottom: var(--double-spacing);
    }
  } 
  .gallery-item {
    position: relative;
    overflow: hidden;
    // background-color: #F9FAFC;
    min-height: 252px;
    padding:var(--main-spacing) var(--main-spacing);
    &_with-image {
      grid-row: span 2;
      padding:0;
    }
    h2 {
      @extend %h2-semibold;
      margin:0;
    }
    p {
      @media (max-width: $sm) {
        font-size: 14px;
      }
      @extend %text;
      // padding-right: 32px;
      margin:0;
      margin-top: 14px;
      text-align: justify;
    }
    ul {
      @extend %text;
      padding-right: 72px;
      margin:0;
      margin-top: 14px;
    }
    .headtitle {
      // @media (max-width: $sm) {
      //   font-size: 15px;
      // }
      @extend %headline-semibold;
      margin-top: 14px;
    }
    .two-column-text {
      @media (max-width: $lg) {
        column-count: 1;
      }
      column-count: 2;
      column-gap: 53px;
    }
    &__photo {
      &_container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
      }
      &_main {
        width: 100%;
        max-width: 100%;
        height: 100%;

        object-fit: cover;
        border-radius: 2px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 8px;
      }
    }
  }
  
}