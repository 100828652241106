$xl:1200px;
$lg:992px;
$md:768px;
$sm:576px;

%h2 {
  /* PN Title 2 Regular */
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
}
%h2-title {
  /* PN Title 2 Bold */
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.326451px;
}

.banner {
  @media (max-width: $sm) {
    height: 94px;
  }
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height:189px;
  width:100%;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  h1 {
    @media (max-width: $lg) {
      height:unset;
      white-space: pre-line;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.326451px;
    }
    @extend %h2-title;
    color:white;
  }
  .banner {
    &__title {
      @media (max-width: $lg) {
        height:unset;
      }
      height:265px;
      display: flex;
      align-items: center;
    }
    &__container {
      @media (max-width: $sm) {
        height: 151px;
      }
      width:100%;
      position: relative;
      overflow: hidden;
      display: flex;
      height: 189px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: left;
      justify-content: flex-end;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      background-color: #041837;
    }
    &__img {
      @media (max-width: $md) {
        margin-left: -64px;
      }
      @media (max-width: $sm) {
        margin-left: -58px;
        max-width: 332px;
        min-width: 332px;
        background-size: 100%;
        max-height: 102px;
        min-height: 102px;
      }
      height: 189px;
      width: 579px;
      top: 0px;
      z-index: 0;
      background-repeat: no-repeat;
    }
    
    &__logo {
      
      @media (max-width: $md) {
        height:76px;
        margin-right: 38px;
      }
      @media (max-width: $sm) {
        height: 85px;
        min-height: 85px;
        max-height: 85px;
        background-size: 56%;
        margin-right: 0px;
        margin-top: 32px;
      }
      background-size: 100%;
      height: 128px;
      width: 128px;
      min-height: 128px;
      min-width: 128px;
      margin-top: 18px;
      margin-right: 128px;
      background-repeat: no-repeat;
      &:hover {
        text-decoration: none;
      }
    }
  }
}